import React from "react";
import mobile1 from "../../img/aboutus/aboutusHomeMobile3.webp";
import mobile2 from "../../img/mobilebanner/aboutusHomeMobile2.webp";
import "./AboutUsHome.scss";
import OrangeButton from "../Button/OrangeButton";
const AboutUsHomeMobile = () => {
  return (
    <div>
      <div className="aboutusHomeMobile">
        <div className="aboutusHomeMobileImg1">
          <h1>ABOUT US</h1>
          <img src={mobile1} alt="our brand" />
        </div>
        <div className="aboutusHomeMobileContent">
          <div className="aboutusHomeMobileContentGrid">
            <h3 className="brandHeaderMobile">Our brand</h3>
            <h1>
              {"SOOO GOOD, "}
              <span className="together">NO BAD</span>
            </h1>
            <h3 className="experienceHeaderMobile">THE BEST PLANT-BASED EXPERIENCE</h3>
            <p className="p-regular">
              {
                "We want every bite to deliver all the taste, protein,\n and enjoyment of eating. This alternative protein\n source is a wholesome choice to everyone,\n everywhere."
              }
            </p>
            <OrangeButton msg="Read more" to="/aboutus" color="orange" />
          </div>
          <div className="aboutusHomeMobileImg2">
            <img src={mobile2} alt="our brand" />
            <div className="aboutusHomeMobileContent2">
              <div></div>
              <div style={{ whiteSpace: "pre-wrap" }}>
                <h3 className="innovationHeaderMobile">Our innovation</h3>
                <h1>{"\nIntraPlant\nTech"}</h1>
                <p className="p-regular">For meat-like taste and texture</p>
                <div className="btnWrapper1">
                  <OrangeButton msg="Read more" to="/aboutus#intraplant" color="orange" yellow="#fff" />
                </div>
                <div className="andSymbolMobile">&</div>
                <h1>{"3-step coating\ninnovation"}</h1>
                <p className="p-regular">For maximum crispiness</p>
                <div className="btnWrapper1">
                  <OrangeButton msg="Read more" to="/aboutus#threestep" color="orange" yellow="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="curveBg"></div>
      </div>
    </div>
  );
};

export default AboutUsHomeMobile;
