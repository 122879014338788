import React from "react";
import "./ProductsHome.scss";
import { Carousel } from "react-bootstrap";
import { snackSeriesData } from "./Data";
import { Link } from "gatsby";
const SnackSeries = (props) => {
    const {page}=props;
    return (
        <>
            <div className="ssWrapper" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                {snackSeriesData.map((item, i) => (
                    <Link to={"/products/" + item.path} state={{ productId: item.id }}>
                        <div className="seriesProduct" key={i}>
                            <div className="seriesProductImg">
                                <img loading="lazy" src={item.image} alt={page == "home" ? item.homeAlt : item.productsAlt} />
                            </div>
                            <div className="seriesProductDetails" style={{ height: "120px" }}>
                                <h3>{item.title}</h3>
                                <div className="seriesProductWP">
                                    <p style={{ textTransform: "lowercase" }} className="p-regular">
                                        {item.weight}
                                    </p>
                                    <p style={{ fontSize: "clamp(1rem, 1.67vw, 2rem)" }}>{item.price}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="carouselssWrapper">
                <Carousel className="carouselWrapper  homeprodCarousel" interval={null}>
                    {snackSeriesData.map((item, i) => (
                        <Carousel.Item className="carouselItem carouselSeries" key={i}>
                            <Link to={"/products/" + item.path} state={{ productId: item.id }}>
                                <div className="carouselSeriesProduct">
                                    <div className="carouselseriesProductImg">
                                        <img loading="lazy" src={item.image} alt={page == "home" ? item.homeAlt : item.productsAlt} />
                                    </div>
                                    <div className="carouselseriesProductDetails" style={{ height: "140px" }}>
                                        <h3>{item.title}</h3>
                                        <div className="carouselseriesProductWP">
                                            <p style={{ textTransform: "lowercase" }} className="p-regular">
                                                {item.weight}
                                            </p>
                                            <p style={{ fontSize: "clamp(1rem, 5.6vw, 4rem)" }}>{item.price}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

export default SnackSeries;