import React from "react";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import GreenButton from "../Button/GreenButton";
import OrangeYellowButton from "../Button/OrangeYellowButton";

import "./Hero.scss";
import HeroTab from "./HeroTab";

const Hero = () => {
  return (
    <>
      <div className={`hero-wrapper my__carousel_main`}>
        <Carousel className="carouselWrapper" interval={3000}>
          <Carousel.Item className="carouselItem">
            <StaticImage src="../../img/hero1.webp" alt="So satisfying, you won’t believe it’s made from plants" className="w-100 d-block hero-img" placeholder="none" />
            <Carousel.Caption className="heroContent">
              <h1>FIRST PRIDE Plant-based goodness</h1>
              <h3>Satisfyingly meaty, surprisingly plant.</h3>
              <div className="heroButton">
                <GreenButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero2.webp"
              alt="FIRST PRIDE the best plant-based nuggets. Crispy and juicy, it tastes just like meat."
              className="w-100 d-block hero-img"
              placeholder="blurred"
            />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage src="../../img/hero3.webp" alt="FIRST PRIDE tasty plant-based goodness. Buy now for promotion!" className="w-100 d-block hero-img" placeholder="blurred" />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton msg="explore more" to="/aboutus" />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="cornerGraphic">
          <StaticImage src="../../img/banner_pattern.webp" alt="Patter" className="orangeGraphic" />
        </div>
      </div>
      <HeroTab />
      <div className={`heroWrapperMobile `}>
        <Carousel className="carouselWrapperMobile" interval={3000}>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero1mob.webp"
              alt="So satisfying, you won’t believe it’s made from plants"
              className="w-100 d-block hero-img"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
            />
            <Carousel.Caption className="heroContent">
              <h2>FIRST PRIDE Satisfyingly meaty, surprisingly plant.</h2>
              <h3>
                <span className="boldHero">So Satisfying,</span> You won't believe it's made from plants.
              </h3>
              <div className="heroButton">
                <GreenButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero2mob.webp"
              alt="FIRST PRIDE the best plant-based nuggets. Crispy and juicy, it tastes just like meat."
              className="w-100 d-block hero-img"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
            />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carouselItem">
            <StaticImage
              src="../../img/hero3mob.webp"
              alt="FIRST PRIDE tasty plant-based goodness. Buy now for promotion!"
              className="w-100 d-block hero-img"
              layout="constrained"
            />
            <Carousel.Caption className="heroContent">
              <div className="heroButton">
                <OrangeYellowButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="cornerGraphic">
          <StaticImage src="../../img/banner_pattern.webp" alt="Patter" className="orangeGraphic" />
        </div>
      </div>
    </>
  );
};
export default Hero;
