import React from "react";
import AboutUsHome from "../components/AboutUsHome/AboutUsHome";
import AboutUsHomeMobile from "../components/AboutUsHome/AboutUsHomeMobile";
import Faqs from "../components/Faq/faqs";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout";
import ProductsHome from "../components/ProductsHome/ProductsHome";
import WhereToBuyUs from "../components/WhereToBuyUs/wheretobuyus";
import Helmet from "react-helmet";

const Home = () => {
    return (
        <Layout title="FIRST PRIDE So satisfying, you won’t believe it’s made from plants" description="FIRST PRIDE The best of plant-based. Have fun with our tasty variety of plant-based products... it's so good, you won’t believe it's made from plants.">
            <Helmet> 
                <link rel="alternate" href="https://www.firstpride.sg/" hreflang="en-sg" />
                <link rel="alternate" href="https://www.firstpridethailand.com/" hreflang="en-th" />
            </Helmet>
            <Hero />
            <ProductsHome />
            <AboutUsHome />
            <AboutUsHomeMobile />
            <WhereToBuyUs heading={true} color="orange" />
            <Faqs />
        </Layout>
    );
};

export default Home;

