import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./faqs.scss";
import GreenButton from "../Button/GreenButton";

const Faqs = () => {
  return (
    <div className="faqsSection">
      <div className="faqsWrapper">
        <h2>FAQ</h2>
        <h5 className="p-regular">Curiosity is healthy. Get your questions answered here.</h5>
        <div className="btnBox">
          <GreenButton icon="forward" msg="View" to="/faq" />
        </div>
      </div>
      <div className="faqsImg">
        <StaticImage src="../../img/faqs1.webp" placeholder="blurred" />
      </div>
    </div>
  );
};

export default Faqs;
