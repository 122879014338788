import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SnackSeries from "./SnackSeries";
// jseries release is not ready yet- import JSeries from "./JSeries";
import "react-tabs/style/react-tabs.css";
import "./ProductsHome.scss";
import GreenButton from "../Button/GreenButton";

const ProductsHome = () => {
  return (
    <div className="productsHomeComponent">
      <div className="productsHomeWrapper">
        <h2>Our products</h2>
        <h5
          style={{ marginBottom: "0" }}
          dangerouslySetInnerHTML={{
            __html: "Think plant-based food is boring? Think&nbsp;again.<br/>First pride offers a delicious range of <b>plant-based protein</b>  that is easy to enjoy.",
          }}
        >
          {}
        </h5>
        <div className="productsHomeTabs">
          <Tabs>
            <TabList>
              <Tab selectedTabClassName="react-tabs__tab--selected">
                <StaticImage src="../../img/snackseries.webp" alt="SnackSeries" className="tabImage" />
              </Tab>
              {/* <Tab selectedTabClassName="react-tabs__tab--selected">
                <StaticImage src="../../img/J-series.webp" alt="JSeries" className="tabImage" />
              </Tab> */}
            </TabList>
            <TabPanel>
              <h2>
                <SnackSeries page="home" />
              </h2>
            </TabPanel>
            {/* <TabPanel>
              <h2>
                <JSeries page="home" />
              </h2>
            </TabPanel> */}
          </Tabs>
        </div>
        <div className="heroButton">
          <GreenButton msg="View All Products" to="/products" />
        </div>
      </div>
    </div>
  );
};

export default ProductsHome;
