import popcornBites from "../../img/products/snackseries/pb1.webp";
import crispyStrips from "../../img/products/snackseries/cs1.webp";
import crispyNuggets from "../../img/products/snackseries/cn1.webp";
import spicyNuggets from "../../img/products/snackseries/sn1.webp";
import cookedMince from "../../img/products/jseries/jscm1.webp";
import crispyKatsu from "../../img/products/jseries/jsck1.webp";

export const snackSeriesData = [
    {
        id: 0,
        path: "popcorn-bites/",
        title: "popcorn bites",
        weight: "(170g)",
        price: "4.25 SGD",
        image: popcornBites,
        homeAlt: "FIRST PRIDE Plant-based Popcorn Bites",
        productsAlt:"Popcorn Bites, plant-based popcorn chicken"
    },
    {
        id: 1,
        path: "crispy-nuggets/",
        title: "crispy nuggets",
        weight: "(170g)",
        price: "4.25 SGD",
        image: crispyNuggets,
        homeAlt:"FIRST PRIDE Plant-based Crispy Nuggets",
        productsAlt: "Plant-based chicken nuggets",
    },
    {
        id: 2,
        path: "crispy-strips/",
        title: "crispy strips",
        weight: "(170g)",
        price: "4.25 SGD",
        image: crispyStrips,
        homeAlt:"FIRST PRIDE Plant-based Crispy Strips",
        productsAlt: "Plant-based chicken strips",
    },
    {
        id: 3,
        path: "spicy-nuggets/",
        title: "spicy nuggets",
        weight: "(170g)",
        price: "4.25 SGD",
        image: spicyNuggets,
        homeAlt:"FIRST PRIDE Plant-based Spicy Nuggets",
        productsAlt: "Plant-based Spicy Chicken Nuggets",
    },
    
];

export const jSeriesData = [
    {
        id: 4,
        path: "cooked-mince/",
        title: "cooked mince",
        weight: "(180g)",
        price: "4.25 SGD",
        image: cookedMince,
        alt: "Coocked Mince",
    },
    {
        id: 5,
        path: "crispy-katsu/",
        title: "crispy katsu (cutlets)",
        weight: "(160g)",
        price: "4.25 SGD",
        image: crispyKatsu,
        alt: "Crispy Katsu",
    },
];