import React from "react";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import GreenButton from "../Button/GreenButton";
import OrangeYellowButton from "../Button/OrangeYellowButton";
import "./Hero.scss";
const HeroTab = () => {
  return (
    <div className={`heroWrapperTab `}>
      <Carousel className="carouselWrapperMobile" interval={3000}>
        <Carousel.Item className="carouselItem">
          <StaticImage
            src="../../img/hero1.webp"
            alt="FIRST PRIDE So satisfying, you won’t believe it’s made from plants"
            className="w-100 d-block hero-img"
            placeholder="blurred"
          />
          <Carousel.Caption className="heroContent">
            <h2>FIRST PRIDE Plant-based goodness</h2>
            <h3>Satisfyingly meaty, surprisingly plant.</h3>
            <div className="heroButton">
              <GreenButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
          <StaticImage
            src="../../img/hero2.webp"
            alt="FIRST PRIDE the best plant-based nuggets. Crispy and juicy, it tastes just like meat."
            className="w-100 d-block hero-img"
            placeholder="blurred"
          />
          <Carousel.Caption className="heroContent">
            <div className="heroButton">
              <OrangeYellowButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
          <StaticImage src="../../img/hero3.webp" alt="FIRST PRIDE tasty plant-based goodness. Buy now for promotion!" className="w-100 d-block hero-img" placeholder="blurred" />
          <Carousel.Caption className="heroContent">
            <div className="heroButton">
              <OrangeYellowButton msg="explore more" color="green" to="/aboutus" yellow="#FFE055" />
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="cornerGraphic">
        <StaticImage src="../../img/banner_pattern.webp" alt="Patter" className="orangeGraphic" />
      </div>
    </div>
  );
};

export default HeroTab;
