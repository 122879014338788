import React from "react";
import "./AboutUsHome.scss";
import banner1 from "../../img/aboutusHome1.webp";
import banner2 from "../../img/aboutusHome3.webp";
import mobilebanner2 from "../../img/mobilebanner/aboutusHomeMobile2.webp";
import OrangeButton from "../Button/OrangeButton";
const AboutUsHome = () => {
  return (
    <div className="aboutusHome">
      <img src={banner1} alt="Banner 1" className="bannerImg" />
      <div className="aboutusHomeContent">
        <h1 className="aboutusHomeHeader">ABOUT US</h1>
        <div className="aboutusHomeContentWrapper">
          <div className="aboutusHomeContentGrid">
            <h3 className="brandHeader">Our brand</h3>
            <h1>
              {"SOOO GOOD, "}
              <span className="together">NO BAD</span>
            </h1>
            <h3 className="experienceHeader p-regular">THE BEST PLANT-BASED EXPERIENCE</h3>
            <p className="p-regular">
              {
                "We want every bite to deliver all the taste, protein,\n and enjoyment of eating. This alternative protein\n source is a wholesome choice to everyone,\n everywhere."
              }
            </p>
            <OrangeButton msg="Read more" to="/aboutus" color="orange" yellow="#fff" />
            <div className="bgdarkgreen"></div>
          </div>
        </div>
        <div className="aboutusHomeContentWrapper2">
          <img src={banner2} alt="Banner 2" className="bannerImg2" />
          <img src={mobilebanner2} alt="Banner 2" className="bannerImgMobile" />
          <div className="aboutusHomeContent2">
            <div></div>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <h3 className="innovationHeader">Our innovation</h3>
              <h1>{"IntraPlant\nTech"}</h1>
              <p className="p-regular">For meat-like taste and texture</p>
              <div className="btnWrapper1">
                <OrangeButton msg="Read more" to="/aboutus#intraplant" color="orange" yellow="#fff" />
              </div>
              <div className="andSymbol">&</div>
              <h1>{"3-step coating\ninnovation"}</h1>
              <p className="p-regular">For maximum crispiness</p>
              <div className="btnWrapper1">
                <OrangeButton msg="Read more" to="/aboutus#threestep" color="orange" yellow="#fff" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHome;
